<template>
  <div id="card-stats">
    <b-alert variant="info" show class="my-0 clickable" 
    @click.native="$router.push('/produce')"
    v-analytics:click.native="['open_produce',{element:'Dashboard'}]"
    >
      <h6 class="text-center">Produce Bulked</h6>
      <dot-flash loaderClass="loader-primary" :status="produce_av.status"></dot-flash>
      <h2 class="text-center" v-show="produce_av.active">KG {{ produce_av.qty }}</h2>
    </b-alert>
    <b-alert variant="success" show class="my-0 clickable" 
    @click.native="$router.push('/payments')"
    v-analytics:click.native="['open_payments',{element:'Dashboard'}]"
    >
      <h6 class="text-center">Payments Pending</h6>
      <dot-flash loaderClass="loader-success" :status="payments_pe.status"></dot-flash>
      <h2 v-show="payments_pe.active">UGX {{ payments_pe.qty }}</h2>
    </b-alert>
    <b-alert variant="danger" show class="my-0 clickable" 
    @click.native="$router.push('/farm_inputs/advances')"
    v-analytics:click.native="['open_advances',{element:'Dashboard'}]"
    >
      <h6 class="text-center">Advances Due</h6>
      <dot-flash loaderClass="loader-danger" :status="advances_due.status"></dot-flash>
      <h2 class="text-center" v-show="advances_due.active">UGX {{ advances_due.qty }}</h2>
    </b-alert>
    <b-alert show class="my-0 clickable" variant="primary">
      <h6 class="text-center">Total Acres</h6>
      <dot-flash loaderClass="loader-primary" :status="total_acreage.status"></dot-flash>
      <h2 class="text-center" v-show="total_acreage.active">{{ total_acreage.qty }}</h2>
    </b-alert>
    <b-alert variant="light" show class="card-bdr clickable" 
    @click.native="$router.push('/suppliers/farmers')"
    v-analytics:click.native="['open_farmers',{element:'Dashboard'}]"
    >
      <h6 class="text-center">Total farmers</h6>
      <dot-flash loaderClass="loader-secondary" :status="farmers.status"></dot-flash>
      <h2 class="text-center" v-show="farmers.active">{{ farmers.qty }}</h2>
    </b-alert>
    <b-alert variant="white" show class="card-bdr clickable" 
    @click.native="$router.push('/suppliers/agents')"
    v-analytics:click.native="['open_village_agents',{element:'Dashboard'}]"
    >
      <h6 class="text-center">Village Agents</h6>
      <dot-flash loaderClass="loader-secondary" :status="vas.status"></dot-flash>
      <h2 class="text-center" v-show="vas.active">{{ vas.qty }}</h2>
    </b-alert>
  </div>
</template>

<script>
import DotFlash from '@/components/loaders/DotFlash.vue';
export default {
  components: { DotFlash },
  name: "card-stats",
  props: [
    "farmers",
    "vas",
    "total_acreage",
    "produce_av",
    "payments_pe",
    "advances_due",
  ],
};
</script>

<style lang="scss">
#card-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  text-align: center;
  h2{
    font-size: 28px;
    font-weight: 600;
  }
}
.clickable:hover{
  cursor: pointer;
}
</style>