import { axiosApiInstance } from "@/plugins/axios.js";
import moment from "moment";
import { add_pid } from "../../../utils.js";
import { PRODUCE_BASE, SEASONS } from "../../constants.js"
/**
 * 
 * @typedef {object} Crop
 * @property {string} crop
 * @property {Array<string>} varieties
 */

/**
 * @param {number} project_id
 */ 
 async function get_crops(project_id) {
    const res = await axiosApiInstance({
        url: add_pid(PRODUCE_BASE,project_id) + "/crops",
    });
    return res.data;
}
function set_season(itarget) {
    itarget.season = moment(itarget.season).toDate();
    let month = itarget.season.toLocaleString("default", { month: "short" });
    month = month.charAt(0).toUpperCase() + month.slice(1);
    // console.log(month);
    let year = itarget.season.getFullYear();
    if (SEASONS[0].includes(month)) {
        itarget.season = year.toString() + "-" + "A";
    } else {
        itarget.season = year.toString() + "-" + "B";
    }
    return itarget;
}
/**
 * @param {number} project_id
 */ 
 async function save_produce_targets(project_id,ptargets) {
    async function prepare() {
        const targets = ptargets.map(set_season).map((el)=>{
            el.crop = el.crop.name;
            el.variety = el.variety.name;
            return el;
        })
        return targets;
    }
    const targets = await prepare();
    const res = await axiosApiInstance.post(
        add_pid(PRODUCE_BASE,project_id) + "/targets", targets
     );
    return res.data;
}

export { get_crops, save_produce_targets}