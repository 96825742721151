import moment from "moment";
import { axiosApiInstance } from "@/plugins/axios.js"
import { PRODUCE_INPUTS} from "../../constants.js";
import { add_pid, pluck } from "../../../utils"
import { chartOptions } from "./models"
function start_end(period, specific) {
    const fmt = "YYYY-MM-DD";
    const firstDay = period.startOf(specific).format(fmt);
    const lastDay = period.endOf(specific).format(fmt);
    return [firstDay, lastDay];
}
/**
* @param {{general:string,specific:string}} prange
* @returns {Array<string,string>}
*/
function get_period(prange) {
    const period = new moment().subtract(1, prange.general);
    return start_end(period, prange.specific);
}
/**
* @param {{general:string,specific:string}} prange
* @returns {Array<string,string>}
*/
function this_month(prange) {
    const period = new moment();
    return start_end(period, prange.specific);
}

/**
 * @param {number} project_id
 * @param {Array<string,string>} date_range
 */
function get_input_advances(project_id, date_range) {
    const [start, end] = date_range;
    axiosApiInstance({
        url: add_pid(PRODUCE_INPUTS, project_id) + "/advances",
        params: { "start-date": start, "end-date": end }
    }).then(res => {
        this.aseries = [{ name: "Cash", data: res.data.map(pluck("cashlog")) }];
        this.aoptions = { ...chartOptions, 
            xaxis: { categories: res.data.map(pluck("advance_type")) },
            tooltip:{
                y:{
                    formatter:(value)=>{return Math.floor(Math.pow(10,value)).toLocaleString();}
                }
            }
         }
    });
}
/**
 * @param {number} project_id
 * @param {Array<string,string>} date_range
 */
function get_total_advances(project_id,date_range) {
    const [start, end] = date_range;
    axiosApiInstance({
        url: add_pid(PRODUCE_INPUTS, project_id) + "/advance_total",
        params:{"start-date":start,"end-date":end}
    }).then(res => {
        this.adv_total = parseInt(res.data).toLocaleString();
    });
}
export { get_period, this_month, get_total_advances, get_input_advances };