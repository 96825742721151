<template>
  <div>
    <b-row class="m-0 border-top pt-4">
      <b-col class="px-0">
        <h5 class="subtitle m-0">PRODUCE TARGETS</h5>
      </b-col>
      <div class="col-auto px-0">
        <b-button
          class="btn-sm"
          variant="outline-primary"
          @click="manageTargets"
          :disabled="!can('view','accounts')"
          v-analytics:click="['manage_targets',{element:'Dashboard'}]"
        >
          <b-icon-gear></b-icon-gear> Manage Targets
        </b-button>
      </div>
    </b-row>
    <div class="d-flex my-2 align-items-center">
      <div class="col-5">
        <b-select v-model="yr_selected">
          <b-select-option yr_selected :value="0">All Years</b-select-option>
          <b-select-option :value="yr" v-for="yr in yrange" :key="'yr-' + yr">{{
            yr
          }}</b-select-option>
        </b-select>
      </div>
      <div class="col-7">
        <b-form-radio-group v-model="season">
          <b-form-radio value="A">Jan to Jul</b-form-radio>
          <b-form-radio value="B">Aug to Dec</b-form-radio>
        </b-form-radio-group>
      </div>
    </div>
    <div class="overflow-auto">
      <div style="min-height: 250px">
        <b-table
          :fields="fields"
          :items="items"
          :per-page="5"
          :current-page="currentPage"
        >
          <template #empty>
            <h5>
              No targets in this period,click
              <b-link @click="manageTargets" variant="primary">here</b-link> to
              set
            </h5>
          </template>
          <template #cell(value_chain)="data">
            <b-link @click="editTarget(data.item.id)" v-analytics:click="['open_target',{element:'Dashboard'}]">
              {{ data.item.crop }} {{ data.item.variety | fmtNa}}
            </b-link>
          </template>
          <template #cell(target)="data"> {{ data.item.target }} T </template>
          <template #cell(actual)="data">
            {{ data.item.actual.toFixed(2) }} T
          </template>
          <template #cell(percent)="data">
            <div style="width: 100px" class="mt-2">
              <b-progress
                :value="percentage(data.item).value"
                :variant="percentage(data.item).category"
                style="height: 10px"
              ></b-progress>
            </div>
          </template>
        </b-table>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="5"
        class="b-pagination-class"
      ></b-pagination>
    </div>
    <ripple :status="produce_targets.status"></ripple>
    <manage-targets></manage-targets>
    <edit-target :target_id="target_id"></edit-target>
  </div>
</template>

<script>
import Ripple from "@/components/loaders/Ripple.vue";
import ManageTargets from "./ManageTargets.vue";
import EditTarget from "./EditTarget.vue";
import { can } from '../../auth/utils';
import {fmtNa} from "../libraries/utils.string";
import {seasonMixin} from "../libraries/utils.season";
export default {
  components: { ManageTargets, Ripple,EditTarget },
  mixins:{seasonMixin},
  props: ["produce_targets", "yrange"],
  filters:{
    fmtNa,
  },
  data() {
    let fields = [
      // A regular column
      { key: "value_chain", label: "Produce" },
      // "variety",
      // A regular column
      { key: "target", label: "Target",class:"prod-unit"},
      // A regular column
      { key: "actual", label: "Actual" },
      // A virtual column made up of percentage
      { key: "percent", label: " " },
    ];

    function percentage({ target, actual }) {
      let result = (actual / target) * 100;
      let category = "danger";
      if (result > 100) {
        result = 100;
      }
      if (result > 90) {
        category = "success";
      }
      if (result > 50 && result < 90) {
        category = "primary";
      }
      return { value: result, category };
    }
    return {
      fields,
      season: "B",
      target_id:"",
      yr_selected: 0,
      currentPage: 1,
      percentage,
    };
  },
  mounted(){
    this.season = this.is_seasonA()?"A":"B";
  },
  computed: {
    items() {
      //** @let {Function} predicate */
      let predictate;
      if (this.yr_selected) {
        predictate = (x) => {
          const chk_str = this.yr_selected.toString() + "-" + this.season;
          return x.season == chk_str;
        };
      } else {
        predictate = (x) => {
          return x.season.includes(this.season);
        };
      }
      return this.produce_targets.targets.filter(predictate);
    },
  },
  methods: {
    can,
    manageTargets() {
      this.$bvModal.show("manage-targets");
    },
    editTarget(target_id){
      this.target_id = target_id;
      this.$nextTick(()=>{
        this.$bvModal.show("edit-target");
      })
    }
  },
};
</script>
<style>
.prod-unit{
  width:20px;
}
</style>
<style lang="scss" scoped->
.table.tb-small {
  thead th {
    padding: 8px 4px !important;
  }

  td {
    padding: 8px 4px !important;
    font-size: 14px;
    &:nth-child(2) {
      font-size: 13px;
      opacity: 0.75;
    }
    &:last-child {
      width: 1px;
    }
  }
}
</style>