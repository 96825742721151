/**
 * 
 * @typedef {object} Crop
 * @property {string} crop
 * @property {Array<string>} varieties
 */


/**
 * 
 * @param {Crop[]} crops 
 * @returns {Array<string>}
 */
 function select_crops(crops) {
    /**@const {Array<string>} crop_list */
    const crop_list = crops.map(el => {
        return { text: el["crop"], value: el["crop"] }
    });
    return crop_list
}
/**
 * 
 * @param {string} crop_name 
 * @param {Crop[]} crops 
 */
function select_varieties(crop_name, crops) {
    return crops.find((crop) => crop.crop == crop_name).varieties;
}
export {select_crops,select_varieties}