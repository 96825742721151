import { axiosApiInstance } from "@/plugins/axios.js";
import { PRODUCE_BASE } from "../constants.js";
import { add_pid, pluck_many, to_tonnes } from "../../utils.js";
// import euro from "@highcharts/map-collection/countries/ug/ug-all.geo.json";
import euro from "@/assets/uganda_new.json";
// console.log(euro);
let map_options = {
  chart: {
    borderWidth: 0,
    map: euro,
  },
  title: {
    text: "PRODUCE MAP DISTRIBUTION",
  },
  subtitle: {
    // text: "Demo of produce map distribution",
    text:""
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      name: "Tonnes",
      data: [],
      dataLabels: {
        enabled: false,
      },
    },
  ],
};

/**
 * @param {{district:string,quantity:number}} item
 * @returns {{district:string,quantity:number,hc_key:string}}
 */
function add_key(item) {
  const nu_item = {
    ...item,
    hc_key: euro.features.filter((x) => {
      let district = item.district.charAt(0).toUpperCase() + item.district.slice(1).toLowerCase();
      return x.properties.name == district;
    })[0].properties["hc-key"],
  };
  return nu_item;
}
/**
 * @param {Array<{district:string,quantity:number}>} info
 */
function pre_format(info) {
  const known_districts = new Set(euro.features.map(el=>el.properties.name));
  const data = info.filter(x=>known_districts.has(x.district));
  return data
    .map(add_key)
    .map(pluck_many(["hc_key", "quantity"]))
    .map((el) => {
      return [el[0], to_tonnes(el[1])];
    });
}
/**
 * @param {number} project_id
 */
function get_produce_districts(project_id) {
  axiosApiInstance({
    url: add_pid(PRODUCE_BASE, project_id) + "/districts",
  }).then((res) => {
    this.moptions = {
      ...map_options,
      series: [
        {
          name: "District: Tonnes",
          data: pre_format(res.data),
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
  });
}
export { map_options, get_produce_districts };
