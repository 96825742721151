<template>
  <apexchart type="bar" height="200" :options="chartOptions" :series="series"></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components:{
    apexchart:VueApexCharts
  },
  props:["series","chartOptions"]
};
</script>

<style>
</style>