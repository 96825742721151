<template>
  <div class="load-container position-relative loader-primary">
    <div class="load-pos">
      <div class="lds-ripple" v-show="status == states.LOADING">
        <div></div>
        <div></div>
      </div>
      <div v-show="status == states.FAIL">
        <b-icon-exclamation-triangle variant="danger" class="text-exclaim"></b-icon-exclamation-triangle>
        <span class="text-danger">&nbsp;Server Error</span>
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/loaders/styles.css"></style>
<style scoped>
.text-exclaim{
  font-size: 1.5em;
}
.load-container {
  width: 100%;
  height: 100%;
}
.load-pos {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateY(-100%);
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid;
  border-color: var(--app-primary-color);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
<script>
import { loadMixin } from ".";
export default {
  mixins: [loadMixin],
};
</script>