<template>
  <div class="card h-100">
  <div class="card-header p-4">
    <h5 class="m-0">PRODUCE MAP DISTRIBUTION</h5>
  </div>
    <highmaps :options="moptions"></highmaps>
  </div>
    
</template>

<script>
import Highcharts from "highcharts";
import loadMap from "highcharts/modules/map.js";
import { genComponent } from "vue-highcharts";
loadMap(Highcharts);
// import euro from "@highcharts/map-collection/countries/ug/ug-all.geo.json";
import {map_options,get_produce_districts} from "../libraries/produce_map.js"

export default {
  components: { Highmaps: genComponent("Highmaps", Highcharts) },
  props:["project_id"],
  mounted(){
    this.get_produce_districts(this.project_id);
  },
  data() {
    // console.log(map_options.chart.map.features);
    return {
      moptions:map_options,
    };
  },
  methods:{
    get_produce_districts
  }
};
</script>

<style>
</style>