import { axiosApiInstance,axiosApiInst } from "@/plugins/axios";
import { add_pid } from "@/modules/utils.js";
import { TARGET_BASE } from "../../../constants.js"
import {TargetSchema} from "./schemas.js"
var TargetValidator = require('jsonschema').Validator;

/**
 * 
 * @typedef {object} Target
 * @property {string} crop
 * @property {string} variety
 * @property {string} season
 */

/**
 * 
 * @param {number} project_id
 * @param {string} id
 * @returns {Promise<Target>}
 */
async function get(project_id,id){
    // console.log(id);
    const res = await axiosApiInstance({
        url:add_pid(TARGET_BASE,project_id)+"/"+id
    });
    return res.data;
}
/**
 * 
 * @param {number} project_id
 * @param {string} id
 * @returns {Promise<string>}
 */
async function remove(project_id,id){
    const res = await axiosApiInstance.delete(add_pid(TARGET_BASE,project_id)+"/"+id);
    return res.data;
}
/**
 * 
 * @param {number} project_id
 * @param {string} id
 * @param {Target} target
 * @returns {Promise<Target>}
 */
async function update(project_id,id,target){
    try{
        new TargetValidator().validate(target, TargetSchema, {nestedErrors: true,throwAll:true});
        const res = await axiosApiInst.patch(add_pid(TARGET_BASE,project_id)+"/"+id,target)
        return res.data;
    }catch(err){
        console.error(err);
    }
}
export {get,remove,update}