const TargetSchema = {
    type:"object",
    properties:{
        id:{type:"string"},
        crop:{type:"string"},
        variety:{type:"string"},
        target:{type:"number"},
        season:{type:"object",properties:{year:{type:"number"},season:{type:"string"}}}
    },
    required:["id","crop","variety","target","season"]
}
export {TargetSchema}