// import { axiosApiInstance } from "@/plugins/axios.js";
import { PRODUCE_BASE } from "../constants.js";
import { add_pid, pluck, pluck_many, to_tonnes } from "../../utils.js";
let column_series = [
  {
    name: "Actual",
    data: [],
    color: "#1F4564",
  },
  {
    name: "Expected",
    data: [],
    color: "#4CAB94",
  },
];
let column_options = {
  chart: {
    type: "bar",
    height: 350,
  },
  noData: {
    text: "Set target to view produce when bulked",
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    title: {
      text: "Tonnes",
    },
  },
};

/**
 * @param {object} data
 * @param {string} srange
 */
function get_series(data, srange) {
  // let series = column_series;

  let prom = new Promise((resolve) => {
    const actual = data[srange].map(pluck("actual")).map(to_tonnes);
    const target = data[srange].map(pluck("target")).map(to_tonnes);
    resolve([actual, target]);
  });
  return prom;
}
/**
 * @param {string} crop
 * @param {string} variety
 * @returns {string}
 */
function make_label(crop, variety) {
  return `${crop}(${variety})`;
}
/**
 * @param {object} data
 * @param {string} srange
 * @returns {Promise<Array<string>>}
 */
async function get_labels(data, srange) {
  const tmp = data[srange].map(pluck_many(["crop", "variety"]));
  return tmp.map((el) => {
    return make_label(el[0], el[1]);
  });
}
/**
 * @param {number} project_id
 * @param {number|string} year
 * @param {import("axios").AxiosInstance} axiosApiInstance
 */
async function get_volumes(project_id, year = "all",axiosApiInstance) {
  const res = await axiosApiInstance.get(add_pid(PRODUCE_BASE, project_id) + "/biannual_targets",{params:{year}})
  const data = res.data;
  const rangeA = "jan-jul";
  const rangeB = "aug-dec";
  const [seriesa,seriesb] = [await get_series(data,rangeA),await get_series(data,rangeB)];
  const [labelsa,labelsb] = [await get_labels(data,rangeA),await get_labels(data,rangeB)];
  const aseries = [
    { ...column_series[0], data: seriesa[0] },
    { ...column_series[1], data: seriesa[1] },
  ]
  const bseries = [
      { ...column_series[0], data: seriesb[0] },
      { ...column_series[1], data: seriesb[1] },
    ];

  const aoptions = { ...column_options, xaxis: { categories: labelsa } }
  const boptions = { ...column_options, xaxis: { categories: labelsb } }

  return [aseries,bseries,aoptions,boptions];
}
export { column_series, column_options, get_volumes };
