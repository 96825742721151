<template>
  <div class="card card-bdr">
    <b-card-header>
      <div class="d-flex align-items-center">
        <h5>VOLUME OF PRODUCE COLLECTED</h5>
        <div class="ml-auto">
          <b-select v-model="selected" @change="()=>{getVolumes(project_id,selected)}" 
            v-analytics:change="['filter_dashboard_produce',{element:'Dashboard'}]">
            <b-select-option selected value="all">All Years</b-select-option>
            <b-select-option
              :value="yr"
              v-for="yr in yrange"
              :key="'yr-' + yr"
              >{{ yr }}</b-select-option
            >
          </b-select>
        </div>
      </div>
    </b-card-header>
    <div>
      <b-tabs>
        <b-tab title="Jan-July" :active="is_seasonA()">
          <column-graph
            :series="aseries"
            :chartOptions="aoptions"
          ></column-graph>
        </b-tab>
        <b-tab title="Aug-Dec" :active="!is_seasonA()">
          <column-graph
            :series="bseries"
            :chartOptions="boptions"
          ></column-graph>
        </b-tab>
      </b-tabs>
      <ripple :status="vloader"></ripple>
    </div>
  </div>
</template>

<script>
import ColumnGraph from "./ColumnGraph.vue";
import {
  column_series,
  column_options,
  get_volumes,
} from "../libraries/time_chart.js";
import Ripple from "@/components/loaders/Ripple.vue";
import { states } from '@/components/loaders';
import {seasonMixin} from "../libraries/utils.season";
export default {
  components: { ColumnGraph, Ripple },
  props: ["yrange", "project_id","axios"],
  mixins:[seasonMixin],
  created() {
    this.getVolumes(this.project_id,this.selected);
  },
  data() {
    return {
      selected: "all",
      aseries: column_series,
      aoptions: column_options,
      bseries: column_series,
      boptions: column_options,
      vloader:states.LOADING
    };
  },
  methods: {
    /**
     * @param {number} project_id
     * @param {string} selected
     */
    getVolumes(project_id, selected) {
      this.vloader = states.LOADING;
      get_volumes(project_id, selected,this.axios).then((results) => {        
        [this.aseries, this.bseries, this.aoptions, this.boptions] = results;
        this.vloader = states.DONE;
      }).catch(()=>{
        this.vloader = states.FAIL;
      });
    },

  },
};
</script>

<style>
</style>