<template>
  <div id="farm-gate" class="my-4">
    <h5 class="subtitle mb-4">FARM-GATE (UNIT) PRICES</h5>
   
    <transition name="fade" :key="current_pcount">
      <b-row class="m-0">
          <div class="col-3 offset-5" v-show="!produce_pricing.active">
            <dot-flash loaderClass="loader-primary" :status="produce_pricing.status"></dot-flash>
          </div>
          <div
            v-show="produce_pricing.active"
            class="col pr-2 pl-0"
            v-for="(product, idx) in produce_pricing.prices"
            :key="'k' + idx"
          >
            <div class="text-center-  lhn prod-title text-capitalize mb-0">
              {{ product.name }}
            </div>
            <div class="text-center- lhn  text-muted text-capitalize- mb-2">
              <!-- <small style="font-size:10px">&lpar; {{ product.variety }} &rpar;</small> -->
              <small style="font-size:12px; text-transform:none; wordwrap:wrap">{{ product.variety | fmtNa}} </small>
              
            </div>
            <div class="text-center-lhn  prod-price fw-bold">
              {{ product.price }} <span v-show="product.increase" class="text-success" >&utrif;</span>
              <span v-show="!product.increase" class="text-danger" >&dtrif;</span>
            </div>
          </div>
      </b-row>
    </transition>
     <div class="d-flex align-items-center mt-3 pr-5">
       <div class="col p-0">
         <b-link class="btn-opt m-0 primary" to="/settings/produce" :disabled="!can('view','accounts')" 
         v-analytics:click="['edit_prices',{element:'Dashboard'}]"> 
          <b-icon-pencil v-show="produce_pricing.prices.length>1"></b-icon-pencil>
          <span v-show="produce_pricing.prices.length>1">Edit Prices</span>
          <b-icon-plus v-show="produce_pricing.prices.length==0"></b-icon-plus>
          <span v-show="produce_pricing.prices.length==0">Farm gate prices not set,click to add</span> 
          
        </b-link>
       </div>
       
      <b-button class="btn-outline" @click="previous"  size="sm" :disabled="current_pcount==1">
        <b-icon-chevron-left></b-icon-chevron-left>
      </b-button>
 
          <span class="text-muted px-2">
        {{ current_pcount }} of {{ Math.ceil(product_count / prange) }}
      </span>
      <b-button class="btn-outline" @click="next" size="sm">
        <b-icon-chevron-right></b-icon-chevron-right>
      </b-button>
    </div>
    
  </div>
</template>

<script>
import DotFlash from "@/components/loaders/DotFlash.vue"
import { PRODUCE_RANGE as produce_range } from "../constants.js";
import { can } from '../../auth/utils.js';
import {fmtNa} from "../libraries/utils.string";
export default {
  props: ["product_count", "produce_pricing"],
  components:{DotFlash},
  data() {
    let current_pcount = 1;
    return {
      current_pcount,
      prange: produce_range,
    };
  },
  filters:{
    fmtNa
  },
  methods: {
    can,
    get_produce_prices() {
      this.$emit("update-pprice", (this.current_pcount - 1) * produce_range);
    },
    previous() {
      this.current_pcount -= 1;
      this.get_produce_prices();
    },
    next() {
      let max_pg = Math.ceil(this.product_count / 6);
      if (this.current_pcount == max_pg) {
        return null;
      }
      this.current_pcount += 1;
      this.get_produce_prices();
    },
  },
};
</script>

<style scoped lang='scss'>

#farm-gate {
  width: 500px;
}
.pricing {
  width: 100px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.btn{
  .b-icon{
     height: 100%;
      
    }
  &.btn-sm{
margin: 0 4px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    padding: 0 !important;
    
  }
  &.btn-outline{
    background: none !important;
    border: 2px solid #C4C4C4;
    color:#c4c4c4;
    text-align: center;
    border-radius: 50% !important;
  }
}

.lhn {
  line-height: 114%;
}
.prod-title{
  color: #1F4564;
}
</style>