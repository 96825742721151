<template>
  <b-modal id="manage-targets" hide-footer title="Manage Targets" size="huge">
    <b-form
      @submit.prevent="
        () => {
          saveProduceTargets();
        }
      "
    >
      <b-button variant="link" @click="createProduceTarget" v-analytics:click="['new_produce_target',{element:'Dashboard'}]">
        &plus; New Produce Target
      </b-button>
      <b-table-simple>
        <b-thead class="d-flex">
          <th class="col-4">Produce</th>
          <th class="col-3">Variety</th>
          <th class="col-2">Target</th>
          <th class="col-3">Due Date</th>
        </b-thead>
        <b-tbody>
          <tr
            v-for="(target, idx) in itargets"
            :key="'t-' + idx"
            class="d-flex"
          >
            <td class="col-4">
              <multiselect
                class="rounded-0"
                v-model="target.crop"
                :options="crop_names"
                placeholder="Search crop"
                label="name"
                track-by="name"
              ></multiselect>
            </td>
            <td class="col-3">
              <multiselect
                v-model="target.variety"
                :options="crop_varieties(target.crop)"
                placeholder="Search variety"
                label="name"
                track-by="name"
              ></multiselect>
            </td>
            <td class="col-2">
              <b-form-input
                v-model="target.target"
                type="number"
                min="1"
                required
              ></b-form-input>
            </td>
            <td class="col-3">
              <b-form-datepicker
                v-model="target.season"
                placeholder="Select date"
                class="rounded-0"
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                }"
              ></b-form-datepicker>
            </td>
          </tr>
        </b-tbody>
      </b-table-simple>
      <div class="ml-auto">
        <b-button variant="primary" type="submit" squared v-analytics:click="['save_produce_target',{element:'Dashboard'}]">
          Save Changes
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import { get_crops, save_produce_targets } from "../libraries/targets/manage.js";
import {bus} from "@/main.js"
export default {
  components: { Multiselect },

  mounted() {
    this.getCrops();
  },
  computed: {
    crop_names() {
      return this.crops.map((el) => {
        return { name: el["crop"], id: el["crop"] };
      });
    },
    crop_varieties() {
      return (crop_name) => {
        let crop = this.crops.filter((el) => {
          return el["crop"] == crop_name.name;
        })[0];
        return crop["varieties"].map((el) => {
          return { name: el, id: el };
        });
      };
    },
    projectId() {
      return this.$store.state.project_id;
    },
  },
  data() {
    return {
      crops: [],
      itargets: [],
      curdate: new Date(),
    };
  },
  methods: {
    getCrops() {
      get_crops(this.projectId).then((crops) => {
        this.crops = crops;
      });
    },
    saveProduceTargets() {
      save_produce_targets(this.projectId, this.itargets).then(() => {
        this.$bvModal.hide("manage-targets");
        bus.$emit("reload-targets");
      });
    },
    createProduceTarget() {
      let crop = this.crop_names[0];
      let variety = this.crop_varieties(crop) | "";
      let target = {
        crop,
        variety,
        target: 1,
        season: "",
      };
      this.itargets = [...this.itargets, target];
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
</style>