import { get_period,get_input_advances,get_total_advances,this_month } from "./views"
import {series,chartOptions} from "./models"
const time_mappings= {
    cur_month:{
        general:"",
        specific:"month"
    },
    last_month:{
        general:"months",
        specific:"month"
    },
    last_qtr:{
        general:"Q",
        specific:"quarter"
    },
    last_year:{
        general:"years",
        specific:"year"
    }
}

export {time_mappings,get_period,get_input_advances,get_total_advances,this_month,series,chartOptions }