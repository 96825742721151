import { axiosApiInstance } from "../../plugins/axios";

function base_axios(){
    const customAxios = Object.assign({},axiosApiInstance);
    return customAxios;
}
function no_cache(){
   const base = base_axios();
   base.defaults.headers.common['Cache-Control'] = 'no-cache';
   return base;
}

export {base_axios,no_cache}
