<template>
  <div class="card h-100">
    <div class="card-header">
      <div class="d-flex align-items-center">
        <h5 class="pl-4">INPUTS OUT</h5>

        <div class="ml-auto">
          <b-select
            v-model="selected"
            v-analytics:change="['filter_advances_dashboard',{element:'Dashboard'}]"
            @change="
              () => {
                get_input_advances(project_id, selected);
                get_total_advances(project_id, selected);
              }
            "
          >
            <b-select-option :value="cur_month" selected
              >This Month</b-select-option
            >
            <b-select-option :value="last_mt">Last Month</b-select-option>
            <b-select-option :value="last_qtr">Last Quater</b-select-option>
            <b-select-option :value="last_yr">Last Year</b-select-option>
          </b-select>
        </div>
      </div>
    </div>
    <div class="p-3">
      <h5 class="text-danger mt-1 font-weight-bold">UGX {{ adv_total }}</h5>
      <h6 class="text-muted mb-2">{{ selected[0] }} to {{ selected[1] }}</h6>
    </div>
    <div>
      <bar-graph :series="aseries" :chartOptions="aoptions"></bar-graph>
    </div>
    <b-link class="text-right m-4" :to="{ path: '/farm_inputs/advances' }"
      v-analytics:click="['open_advances',{element:'Dashboard'}]"
      >Open Advances</b-link
    >
  </div>
</template>

<script>
import BarGraph from "./BarGraph.vue";
import {
  get_period,
  time_mappings,
  this_month,
  get_input_advances,
  get_total_advances,
} from "../libraries/inputs_outs/";
import { series, chartOptions } from "../libraries/inputs_outs/";
export default {
  components: { BarGraph },
  props: ["project_id"],
  mounted() {
    this.get_input_advances(this.project_id, this.selected);
    this.get_total_advances(this.project_id, this.selected);
  },
  data() {
    return {
      adv_total: 0,
      cur_month: this_month(time_mappings.cur_month),
      last_qtr: get_period(time_mappings.last_qtr),
      last_mt: get_period(time_mappings.last_month),
      last_yr: get_period(time_mappings.last_year),
      aseries: series,
      aoptions: chartOptions,
      selected: this_month(time_mappings.cur_month),
    };
  },
  methods: { get_input_advances, get_total_advances },
};
</script>

<style>
</style>