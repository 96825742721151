const series = [
  {
    data: [],
  },
];
const chartOptions = {
  chart: {
    type: "bar",
    height: 178,
  },
  noData: {
    text: "There are no advances at this time",
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
      barHeight: "50%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: [],
  },
};

export { series, chartOptions };
