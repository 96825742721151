/**
 * 
 * @param {string} str 
 */
function fmtNa(str){
    if(str.toUpperCase()=="NA"){
        return "";
    }
    return str;
}
export {fmtNa}