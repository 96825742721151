<template>
  <div class="pl-2 w-100">
    <b-navbar>
      <b-navbar-brand> DASHBOARD </b-navbar-brand>
      <b-navbar-nav class="ml-auto ">
        <button  class="btn-opt primary" @click="reload()" v-analytics:click="['refresh_dashboard',{element:'Dashboard'}]">
          <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
          Refresh
        </button>
           <button class="btn-opt primary" @click="print()" v-analytics:click="['print_dashboard',{element:'Dashboard'}]">
           <b-icon-printer></b-icon-printer> Print
           </button>
           <button class="btn-opt primary" v-show="false" ><b-icon-book></b-icon-book> Export </button>
      </b-navbar-nav>
    </b-navbar>
    <div class="row m-0" ref="printable">
      <b-col cols="7">
        <time-chart :yrange="yrange" :project_id="projectId" :axios="axios"></time-chart>
        <div class="mt-3">
          <div class="row">
            <div class="col-12 col-md pr-0"> <produce-map :project_id="projectId"></produce-map></div>
            <div class="col-12 col-md"> <inputs-out  :project_id="projectId"></inputs-out></div>
          </div>
        </div>
      </b-col>
      <b-col cols="5" class="mr-0">
        <card-stats
          :farmers="farmer_count"
          :vas="va_count"
          :total_acreage="total_acreage"
          :produce_av="produce_av"
          :payments_pe="payments_pe"
          :advances_due="advances_due"
        ></card-stats>
        <farm-gate
          :product_count="product_count"
          :produce_pricing="produce_pricing"
          @update-pprice="getProducePricing"
        ></farm-gate>
        <produce-target
          :produce_targets="produce_targets"
          :yrange="yrange"
        ></produce-target>
      </b-col>
    </div>
  </div>
</template>

<script>
import CardStats from "./components/CardStats.vue";
import InputsOut from "./components/InputsOut.vue";
import ProduceMap from "./components/ProduceMap.vue";
import ProduceTarget from "./components/ProduceTarget.vue";
import TimeChart from "./components/TimeChart.vue";
import FarmGate from "./components/FarmGate.vue";
import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import moment from "moment";
import {
  get_farmer_count,
  get_va_count,
  get_total_acreage,
  get_product_count,
  get_produce_available,
  get_payments_pending,
  get_advances_due,
  get_produce_targets,
  get_produce_yrange,
  get_produce_prices,
  cardStat,
  Pricing,
  Targets
} from "./actions.js";
import {bus} from "@/main.js";
import * as RPC from "./axios";
export default {
  created(){
    bus.$on("reload-targets",()=>{
      this.getProduceTargets();
    });
    this.$analytics.track("load_dashboard",{element:"Dashboard"})
  },
  components: {
    TimeChart,
    CardStats,
    ProduceMap,
    InputsOut,
    ProduceTarget,
    FarmGate,
  },
  computed:{
    /**
     * @returns {number}
     */
    projectId(){
      return this.$store.state.project_id;
    },
    /**
     * @returns {string[]}
     */
    dates(){
    const cb = this.$store.getters["dates/dateRange"];
    const [startDate, endDate] = cb();
    if(startDate==null || endDate==null){
      return [];
    }
    return [startDate,endDate];
    }
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      farmer_count: new cardStat(),
      va_count: new cardStat(),
      total_acreage: new cardStat(),
      product_count: 0,
      produce_pricing: new Pricing(),
      produce_av:new cardStat(),
      payments_pe:new cardStat(),
      advances_due:new cardStat(),
      produce_targets:new Targets(),
      axios:RPC.base_axios(),
      yrange:[]
    };
  },
  methods: {
    reload(){
      // this.$router.go(this.$router.currentRoute);
      this.axios = RPC.no_cache();
      this.load();
    },
    load(){
      this.getFarmerCount();
      this.getVaCount();
      this.getTotalAcreage();
      this.get_product_count(this.projectId,this.axios);
      this.getProduceAvailable();
      this.getPaymentsPending();
      this.getProducePricing(1);
      this.getAdvancesDue();
      this.getProduceTargets();
      this.get_produce_yrange(this.projectId,this.axios);
    },
    print(){
      const elm = this.$refs.printable;
      const doc = new jsPDF('landscape','mm',[360,480]);
      html2canvas(elm).then((canvas)=>{
        const img = canvas.toDataURL("image/jpeg");
        doc.addImage(img,'JPEG',20,20);
        const today= moment().format("YYYY-MM-DD");
        doc.save("dashboard--"+today+".pdf");
      })
    },
    getProduceAvailable(){
      this.produce_av.reload();
      get_produce_available(this.projectId,this.axios,this.dates).then((qty)=>{this.produce_av.done(qty)}).catch(()=>{
        this.produce_av.fail()
      });
    },
    getPaymentsPending(){
      this.payments_pe.reload();
      get_payments_pending(this.projectId,this.axios,this.dates).then((qty)=>{this.payments_pe.done(qty)}).catch(()=>{
        this.payments_pe.fail()
      })
    },
    getAdvancesDue(){
      this.advances_due.reload();
      get_advances_due(this.projectId,this.axios,this.dates).then((qty)=>{
        this.advances_due.done(qty)}).catch(()=>{this.advances_due.fail()});
    },
    getTotalAcreage(){
      this.total_acreage.reload();
      get_total_acreage(this.projectId,this.axios,this.dates).then((qty)=>{this.total_acreage.done(qty)}).catch(()=>{this.total_acreage.fail()});
    },
    getFarmerCount(){
      this.farmer_count.reload();
      get_farmer_count(this.projectId,this.axios,this.dates).then((qty)=>{this.farmer_count.done(qty)}).catch(()=>{
        this.farmer_count.fail()
      });
    },
    getVaCount(){
      this.va_count.reload();
      get_va_count(this.projectId,this.axios,this.dates).then((qty)=>{this.va_count.done(qty)}).catch(()=>{this.va_count.fail()});
    },
    /**
     * @param {number} start
     */
    getProducePricing(start){
      this.produce_pricing.reload();
      get_produce_prices(this.projectId,start,this.axios).then((prices)=>{this.produce_pricing.done(prices)}).catch(()=>{
        this.produce_pricing.fail()})
    },
    getProduceTargets(){
      this.produce_targets.reload();
      get_produce_targets(this.projectId,this.axios).then((targets)=>{this.produce_targets.done(targets)}).catch(()=>{
        this.produce_targets.fail();
      })
    },
    get_product_count,
    get_produce_yrange
  },
};
</script>

<style scoped lang="scss">
</style>