import Vue from "vue";
import moment from "moment";

const seasonMixin = Vue.mixin({
    methods:{
        is_seasonA(){
            const month = moment().month();
            return month <= 6;
        }
    }
});
export {seasonMixin}