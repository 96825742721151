<template>
  <b-modal
    id="edit-target"
    title="Edit Target"
    @show="getTarget"
    size="sm"
    hide-footer
  >
    <dot-flash :status="ldr.status" loaderClass="loader-primary"></dot-flash>
    <div v-show="ldr.active">
      <b-form @submit.prevent="saveTarget">
        <b-form-group label="Crop">
          <b-form-select
            v-model="crop"
            :options="value_chains"
            @change="setVarieties"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Variety">
          <b-form-select v-model="variety" :options="varieties"></b-form-select>
        </b-form-group>
        <b-form-group label="Target">
          <b-form-input v-model="target" type="number"></b-form-input>
        </b-form-group>
        <b-form-group label="Season(year-A,year-B)">
          <b-form-input v-model="season.year" type="number"></b-form-input>
          <b-form-radio-group v-model="season.season">
            <b-form-radio value="A">A</b-form-radio>
            <b-form-radio value="B">B</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-button type="submit" squared variant="outline-primary" size="sm" v-analytics:click="['save_target_edit',{element:'Dashboard'}]">
          <b-icon-archive></b-icon-archive> SAVE
        </b-button>
        &nbsp;
        <b-button
          squared
          variant="outline-danger"
          @click="DeleteTarget"
          size="sm"
          v-analytics:click="['delete_target',{element:'Dashboard'}]"
        >
          <b-icon-trash></b-icon-trash> DELETE
        </b-button>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { loader } from "../../../components/loaders";
import { pluck } from "../../utils.js";
import * as Utils from "../libraries/targets/edit/utils";
import * as Api from "../libraries/targets/edit/api";
import { get_crops } from "../libraries/targets/manage.js";
import DotFlash from "@/components/loaders/DotFlash.vue";
import {bus} from "@/main.js"
export default {
  components: { DotFlash },
  props: ["target_id"],
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  data() {
    return {
      value_chains: [],
      varieties: ["NA"],
      crops: [],
      variety: "NA",
      season: { year: 2021, season: "A" },
      crop: "",
      target: 0,
      ldr: new loader(),
    };
  },
  mounted() {
    this.getCrops();
  },
  methods: {
    getCrops() {
      get_crops(this.projectId).then((crops) => {
        this.crops = crops;
        this.value_chains = crops.map(pluck("crop"));
      });
    },
    setVarieties() {
      this.varieties = Utils.select_varieties(this.crop, this.crops);
    },
    getTarget() {
      this.ldr.reload();
      Api.get(this.projectId, this.target_id).then((info) => {
        [this.crop, this.variety, this.season, this.target] = [
          info.crop,
          info.variety,
          info.season,
          info.target,
        ];
        this.ldr.done();
      });
    },
    makeToast(variant, title, message) {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
      });
    },
    DeleteTarget() {
      Api.remove(this.projectId, this.target_id)
        .then((notes) => {
          this.makeToast("success", "Target Removal", notes);
          this.$bvModal.hide("edit-target");
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.makeToast("danger", "Target Removal", err.response.data);
          }
        });
    },
    saveTarget() {
      const target = {
        season: {...this.season,year:parseInt(this.season.year)},
        crop: this.crop,
        id: this.target_id,
        target: parseInt(this.target),
        variety:this.variety
      };
      this.ldr.reload();
      Api.update(this.projectId,this.target_id,target).then((info)=>{
        [this.crop, this.variety, this.season, this.target] = [
          info.crop,
          info.variety,
          info.season,
          info.target,
        ];
        this.ldr.done();
        bus.$emit("reload-targets");
      }).catch(()=>this.ldr.fail());
    },
  },
};
</script> 